import { Cities } from '../utils/cities';

interface QuinielasProps {
  quinielas: Record<string, string[]>;
  date: Date;
  setDate(d: Date): void;
  setCity(c: string): void;
}

const days = [
  'Domingo',
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
  'Sábado',
];

const relativeDay = ['Hoy', 'Ayer', 'Anteayer'];

export function Quinielas({
  quinielas,
  date,
  setDate,
  setCity,
}: QuinielasProps) {
  return (
    <div className='col s12 center mar2'>
      <div className='p1'>
        <h1 className='p2 first center'>QUINIELA</h1>
      </div>

      <div className='x mar3'>
        <div className='col s2 m2 l2 carobravo'>          
        </div>
        <div className='col s2 m2 carobravo'>
          <p className='a white-text aspen'>10:15</p>
        </div>
        <div className='col s2 m2 carobravo'>
          <p className='a white-text aspen'>12:00</p>
        </div>
        <div className='col s2 m2 carobravo'>
          <p className='a white-text aspen'>15:00</p>
        </div>
        <div className='col s2 m2 carobravo'>
          <p className='a white-text aspen'>18:00</p>
        </div>
        <div className='col s2 m2 carobravo'>
          <p className='a white-text aspen'>21:00</p>
        </div>   
      </div>

      <div className='col s12 agui'>
        {Object.entries(quinielas).map(([city, values]) => (
          <div className='col s12 sabatini' key={city}>
            <div className='col s2 m2 l2 kk9'>
              <h2 className='truncate b'>{city}</h2>
            </div>
            {values.map((v, i) => (
              <div className='col s2 m2' key={i}>
                <p className='c'>{v ? v : <>&nbsp;</>}</p>
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className='col s12'>
        <div className='input-field col s6'>
          <select
            defaultValue={'0'}
            onChange={(e) => {
              setDate(
                new Date(date.getTime() - parseInt(e.target.value) * 8.64e7)
              );
            }}
          >
            {Array(7)
              .fill(0)
              .map((_, i) => {
                const d = new Date(new Date(date.getTime() - i * 8.64e7));
                d.setUTCHours(5);

                if (d.getDay() === 0) return null;

                const relative = relativeDay[i];

                return (
                  <option key={i} value={i}>
                    {[
                      relative,
                      days[d.getDay()],
                      d.toLocaleString('es-ES', { dateStyle: 'long' }),
                    ]
                      .filter(Boolean)
                      .slice(0, 2)
                      .join(', ')}
                  </option>
                );
              })}
          </select>
        </div>

        <div className='input-field col s6'>
          <select
            onChange={(e) => {
              setCity(e.target.value);
            }}
          >
            {Object.keys(Cities).map((c) => (
              <option value={c} key={c}>
                {c}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
}
