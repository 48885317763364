import { useMemo } from 'react';

interface QuinielaCardProps {
  time: keyof typeof times;
  city: string;
  meaningText: string;
  meaningNumber: string;
  numbers: { number: number; value: string }[];
}

const times = {
  previa: 'LA PREVIA 10:15',
  primera: 'LA PRIMERA 12:00',
  matutina: 'MATUTINA 15:00',
  vespertina: 'VESPERTINA 18:00',
  nocturna: 'NOCTURNA 21:00',
};

export function QuinielaCard({
  time,
  city,
  meaningText,
  meaningNumber,
  numbers = [],
}: QuinielaCardProps) {
  const rows = useMemo(
    () => numbers.slice(0, 10).map((n, i) => [n, numbers[i + 10]]),
    [numbers]
  );

  return (
    <div className='col s12 m6 l3'>
      <div className='fondotablero z-depth-2'>
        <p className='negrita'>{times[time]}</p>
        <p className='titulotablero'>{city.toUpperCase()}</p>
        <p className='center'>
          <img
            src={`https://quesalio.com/img/emoticons/${
              meaningNumber || 'empty'
            }.png`}
            className='sigbg3'
            alt={meaningText}
          />
        </p>
        <p className='sig truncate'>{meaningNumber || '-'}</p>
        <p className='significado'>{meaningText || '-'}</p>
        <table className='centered mar10'>
          <tbody className='contenido-tablero'>
            {rows.map((r, i) => (
              <tr key={i}>
                <td className={i === 0 ? 'fondo_posicion1' : 'fondo_posicion'}>
                  {r[0].number}
                </td>
                <td className={i === 0 ? 'fondo_posicion' : ''}>
                  {r[0].value}
                </td>
                <td className='fondo_posicion'>{r[1].number}</td>
                <td>{r[1].value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
