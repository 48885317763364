export function Footer() {
  return (
    <footer className="page-footer">
      <div className="container">
        <div className="row">
          <div className="col l6 s12 center">
            <h5 className="white-text">¿Qué Salió?</h5>
            <p className="grey-text text-lighten-4">
              En caso de discrepancia entre nuestros datos y el extracto oficial tendrá validez este último.
              Los nombres de juegos y productos aquí mencionados pueden ser marcas registradas de sus
              respectivos propietarios.
            </p>
          </div>
          <div className="col l3 s12 center"><a href="https://play.google.com/store/apps/details?id=com.resultados.quesalio">
            <h5 className="white-text">¡Bajate la App!</h5>
            <p><img src="https://quesalio.com/img/promoappstore.png" alt="Instala Qué Salio en tu teléfono" /></p>
          </a>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container center">© QueSalio.com<span role="img" aria-label="liberty">🗽</span></div>
      </div>
    </footer>
  );
}