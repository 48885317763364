export function Navbar() {
  return (
    <div className="navbar-fixed">
      <nav className="nav-extended zz9">
        <div className=" nav-wrapper">
          <a href="/" className="brand-logo q901 leuco">¿Qué Salió?</a>
        </div>
      </nav>
    </div>
  );
}