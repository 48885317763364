export const Quinielas = [
  'Ciudad',
  'Provincia',
  'Córdoba',
  'Entre Ríos',
  'Corrientes',
  'Jujuy',
  'Catamarca',
  'Tucumán',
  'Chubut',
  'Mendoza',
  'Salta',
  'Montevideo',
  'Santa Fe',
  'Chaco',
  'Santiago',
  'Neuquén',
  'San Luis',
  'Formosa',
  'Misiones',
  'San Juan',
  'La Rioja',
] as const;

export const Cities: Record<
  string,
  { ids: string; quinielas: typeof Quinielas[number][] }
> = {
  'Buenos Aires': {
    ids: '25,24,23,38,28,39',
    quinielas: [
      'Ciudad',
      'Provincia',
      'Montevideo',
      'Santa Fe',
      'Córdoba',
      'Entre Ríos',
    ],
  },
  'Entre Ríos': {
    ids: '25,24,38,39,23',
    quinielas: ['Ciudad', 'Provincia', 'Santa Fe', 'Entre Ríos', 'Montevideo'],
  },
  Mendoza: {
    ids: '25,24,53,39',
    quinielas: ['Ciudad', 'Provincia', 'Mendoza', 'Entre Ríos'],
  },
  Córdoba: {
    ids: '25,24,28,39',
    quinielas: ['Ciudad', 'Provincia', 'Córdoba', 'Entre Ríos'],
  },
  Corrientes: {
    ids: '25,24,42',
    quinielas: ['Ciudad', 'Provincia', 'Corrientes'],
  },
  Chaco: {
    ids: '25,24,52',
    quinielas: ['Ciudad', 'Provincia', 'Chaco'],
  },
  Santiago: {
    ids: '25,24,48',
    quinielas: ['Ciudad', 'Provincia', 'Santiago'],
  },
  Neuquen: {
    ids: '25,24,41',
    quinielas: ['Ciudad', 'Provincia', 'Neuquén'],
  },
  'San Luis': {
    ids: '25,24,49,53,28',
    quinielas: ['Ciudad', 'Provincia', 'San Luis', 'Mendoza', 'Córdoba'],
  },
  Salta: {
    ids: '25,24,51,50,55',
    quinielas: ['Ciudad', 'Provincia', 'Salta', 'Jujuy', 'Tucumán'],
  },
  Jujuy: {
    ids: '25,24,50',
    quinielas: ['Ciudad', 'Provincia', 'Jujuy'],
  },
  Tucumán: {
    ids: '25,24,55',
    quinielas: ['Ciudad', 'Provincia', 'Tucumán'],
  },
  Chubut: {
    ids: '25,24,56',
    quinielas: ['Ciudad', 'Provincia', 'Chubut'],
  },
  Formosa: {
    ids: '25,24,59',
    quinielas: ['Ciudad', 'Provincia', 'Formosa'],
  },
  Misiones: {
    ids: '25,24,38,60',
    quinielas: ['Ciudad', 'Provincia', 'Santa Fe', 'Misiones'],
  },
  Catamarca: {
    ids: '25,24,61',
    quinielas: ['Ciudad', 'Provincia', 'Catamarca'],
  },
  'San Juan': {
    ids: '25,24,62',
    quinielas: ['Ciudad', 'Provincia', 'San Juan'],
  },
  'La Rioja': {
    ids: '25,24,63',
    quinielas: ['Ciudad', 'Provincia', 'La Rioja'],
  },
};
