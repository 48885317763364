import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { QuinielaCard } from '../components/QuinielaCard';
import { Quinielas } from '../components/Quinielas';
import { useEffect, useState } from 'react';
import { Cities } from '../utils/cities';

const configQuery = gql`
  query {
    configuration {
      date
    }
  }
`;

const quinielasQuery = gql`
  query quinielas($ids: String, $date: String) {
    previa(ids: $ids, date: $date) {
      id
      city
      value
      id_loteria
      id_draw
      date
      numbers {
        number
        value
      }
      meaning
      meaning_number
      meaning_image
    }

    primera(ids: $ids, date: $date) {
      id
      city
      value
      id_loteria
      id_draw
      date
      numbers {
        number
        value
      }
      meaning
      meaning_number
      meaning_image
    }

    matutina(ids: $ids, date: $date) {
      id
      city
      value
      id_loteria
      id_draw
      date
      numbers {
        number
        value
      }
      meaning
      meaning_number
      meaning_image
    }

    vespertina(ids: $ids, date: $date) {
      id
      city
      value
      id_loteria
      id_draw
      date
      numbers {
        number
        value
      }
      meaning
      meaning_number
      meaning_image
    }

    nocturna(ids: $ids, date: $date) {
      id
      city
      value
      id_loteria
      id_draw
      date
      numbers {
        number
        value
      }
      meaning
      meaning_number
      meaning_image
    }
  }
`;

const times = ['previa','primera', 'matutina', 'vespertina', 'nocturna'] as const;

export function Home() {
  const {
    loading: ldConfig,
    error: errConfig,
    data: config,
  } = useQuery(configQuery);

  const [date, setDate] = useState(new Date());
  const [city, setCity] = useState(Object.keys(Cities)[0]);

  const [getQuinielas, { data: quinielas }] = useLazyQuery(quinielasQuery, {
    variables: {
      ids: Cities[city].ids,
      date: date.toISOString(),
    },
  });

  useEffect(() => {
    if (!ldConfig && !errConfig && config?.configuration?.date) {
      setDate(new Date(config?.configuration?.date));
      getQuinielas();
    }
  }, [ldConfig, errConfig, config, getQuinielas]);

  useEffect(() => {
    if (!ldConfig) getQuinielas();

    // @ts-ignore
    $('select').formSelect();
  }, [date, getQuinielas, city, ldConfig]);

  return (
    <>
      <div className='row'>
        <Quinielas
          quinielas={Object.fromEntries(
            Cities[city].quinielas.map((q: any) => [
              q,
              times.map(
                (t) => quinielas?.[t]?.find((c: any) => c.city === q)?.value
              ),
            ])
          )}
          date={
            new Date(
              config?.configuration?.date ||
                new Date().toISOString().slice(0, 10) + 'T00:00:00.000Z'
            )
          }
          setDate={(d: Date) => setDate(d)}
          setCity={(c: string) => setCity(c)}
        />
      </div>

      {times.map((t, i) => (
        <div className='row' key={i}>
          {Object.values(quinielas?.previa || {}).map((d: any) => {
            const quiniela = quinielas?.[t]?.find(
              (q: any) => q.city === d.city
            );

            if (
              !quiniela ||
              !quiniela.value ||
              !quiniela.numbers.some((n: { value: string }) =>
                Boolean(n?.value)
              )
            )
              return null;

            return (
              <QuinielaCard
                key={t + d.city}
                time={t}
                city={d.city}
                meaningNumber={quiniela?.meaning_number}
                meaningText={quiniela?.meaning}
                numbers={quiniela?.numbers}
              />
            );
          })}
        </div>
      ))}
    </>
  );
}
